/*****************SETUP**********************/
export const GET_ALL_EMPLOYEES="GET_ALL_EMPLOYEES"
export const GET_EMPLOYEES_LIST="GET_EMPLOYEES_LIST"
export const FORM_EMPLOYEE_ADD= "FORM_EMPLOYEE_ADD"
export const FORM_COMPANY_ADD= "FORM_COMPANY_ADD"
export const FORM_DIVISION_ADD= "FORM_DIVISION_ADD"
export const FORM_DEPARTMENT_ADD= "FORM_DEPARTMENT_ADD"
export const FORM_DESIGNATION_ADD= "FORM_DESIGNATION_ADD"
export const FORM_TASK_CREATE= "FORM_TASK_CREATE"
export const COMPANY_LIST= "COMPANY_LIST"
export const DIVISION_LIST= "DIVISION_LIST"
export const DEPARTMENT_LIST= "DEPARTMENT_LIST"
export const DESIGNATION_LIST= "DESIGNATION_LIST"
export const INSERT_EMPLOYEE= "INSERT_EMPLOYEE"
export const INSERT_DESIGNATION= "INSERT_DESIGNATION"
export const INSERT_DEPARTMENT= "INSERT_DEPARTMENT"
export const INSERT_DIVISION= "INSERT_DIVISION"
export const INSERT_COMPANY= "INSERT_COMPANY"
export const INSERT_NEW_TASK= "INSERT_NEW_TASK"
export const DESIGNATION_LIST_VIEW= "DESIGNATION_LIST_VIEW"
export const DEPARTMENT_LIST_VIEW= "DEPARTMENT_LIST_VIEW"
export const DIVISION_LIST_VIEW= "DIVISION_LIST_VIEW"
export const COMPANY_LIST_VIEW= "COMPANY_LIST_VIEW"
export const EMPLOYEE_LIST_VIEW= "EMPLOYEE_LIST_VIEW"
export const TASK_TIMELINE_VIEW="TASK_TIMELINE_VIEW"
export const VIEW_TASK_LIST="VIEW_TASK_LIST"
export const VIEW_RECEIVED_TASK_LIST="VIEW_RECEIVED_TASK_LIST"
export const USER_TASK_LIST="USER_TASK_LIST"
export const VIEW_DASHBOARD="VIEW_DASHBOARD"
export const LOGIN_CHECK ="LOGIN_CHECK"
export const VIEW_PROFILE ="VIEW_PROFILE"
export const SIDEBAR_MENU ="SIDEBAR_MENU"
export const INSERT_TASK_FEEDBACK="INSERT_TASK_FEEDBACK"
export const REPORT_MY_PENDING_TASK="REPORT_MY_PENDING_TASK"
export const REPORT_ASSIGNED_TASK="REPORT_ASSIGNED_TASK"
export const REPORT_TASK="REPORT_TASK"
export const REPORT_ASSIGN_TASK_VIEW="REPORT_ASSIGN_TASK_VIEW"
export const EMPLOYEE_DETAIL="EMPLOYEE_DETAIL"
export const EMPLOYEE_DETAIL_UPDATE="EMPLOYEE_DETAIL_UPDATE"
export const DESIGNATION_DETAIL="DESIGNATION_DETAIL"
export const DESIGNATION_DETAIL_UPDATE="DESIGNATION_DETAIL_UPDATE"
export const DEPARTMENT_DETAIL="DEPARTMENT_DETAIL"
export const DEPARTMENT_DETAIL_UPDATE="DEPARTMENT_DETAIL_UPDATE"
export const DIVISION_DETAIL="DIVISION_DETAIL"
export const DIVISION_DETAIL_UPDATE="DIVISION_DETAIL_UPDATE"
export const COMPANY_DETAIL="COMPANY_DETAIL"
export const COMPANY_DETAIL_UPDATE="COMPANY_DETAIL_UPDATE"
export const DELETE_COMPANY_DETAIL="DELETE_COMPANY_DETAIL"
export const DELETE_DIVISION_DETAIL="DELETE_DIVISION_DETAIL"
export const DELETE_DEPARTMENT_DETAIL="DELETE_DEPARTMENT_DETAIL"
export const DELETE_DESIGNATION_DETAIL="DELETE_DESIGNATION_DETAIL"
export const DELETE_EMPLOYEE_DETAIL="DELETE_EMPLOYEE_DETAIL"
export const LOGOUT_CHECK="LOGOUT_CHECK"
export const TASK_UPDATE="TASK_UPDATE"
export const USER_REQUEST_OTP="USER_REQUEST_OTP"
export const USER_UPDATE_PASSWORD="USER_UPDATE_PASSWORD"
export const ADMIN_DASHBOARD_TASK_LIST="ADMIN_DASHBOARD_TASK_LIST"
export const VIEW_EMPLOYEE_LIST="VIEW_EMPLOYEE_LIST"
export const USER_SUPPORT_TICKET_INSERT="USER_SUPPORT_TICKET_INSERT"
export const USER_SUPPORT_TICKET_VIEW="USER_SUPPORT_TICKET_VIEW"
export const USER_SUPPORT_TICKET_TIMELINE="USER_SUPPORT_TICKET_TIMELINE"
export const ADMIN_SUPPORT_TICKET_LIST="ADMIN_SUPPORT_TICKET_LIST"
export const SEARCH_TASK_DETAIL="SEARCH_TASK_DETAIL"
export const UPDATE_TASK_DETAIL="UPDATE_TASK_DETAIL"
export const UPDATE_USER_LOGIN="UPDATE_USER_LOGIN"
export const USER_ADMIN_LOGIN_CHECK="USER_ADMIN_LOGIN_CHECK"
export const USER_ADMIN_LOGIN="USER_ADMIN_LOGIN"

export const FORM_SUB_DEPARTMENT_ADD= "FORM_SUB_DEPARTMENT_ADD"
export const SUB_DEPARTMENT_LIST_VIEW= "SUB_DEPARTMENT_LIST_VIEW"
export const SUB_DEPARTMENT_DETAIL="SUB_DEPARTMENT_DETAIL"
export const INSERT_SUB_DEPARTMENT="INSERT_SUB_DEPARTMENT"
export const SUB_DEPARTMENT_LIST="SUB_DEPARTMENT_LIST"

