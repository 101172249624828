import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,Modal,ModalBody,Row,Col } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay'
class UserOTPRequest extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewInput:{
        otp:"",
        otp_sms:"",
        mobile:"",
        user_ID:0,
      },
      loading:false,
    }
  } 


  handleSubmit = event => {
    //alert(JSON.stringify(this.state.formNewInput))
    if (this.state.formNewInput.otp===""){
      alert("OTP is Empty")
    }
    else if (this.state.formNewInput.otp!=this.state.formNewInput.otp_sms){
      alert("OTP Not Matched")
    }
    else{
      //this.props.userRegistration("userRegistration",this.state.formNewInput);
      alert("OTP Matched")
      this.props.history.push({pathname: '/user-password-reset',state: this.state.formNewInput })
     //this.setState({loading:true},()=>{console.log("okkkkk")})
    }
  };

  formNewInputChange=(event)=> {

    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewInput
    formTask[key]=value;
    this.setState({formTask})
    console.log(this.state.formNewInput)
    console.log("value"+value)
  }
  onSubmit = () => {
    this.props.history.push('/');
  };
  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <div class="row" style={{display: "flex",justifyContent: "center", alignItems: "center",height:'100vh', color:'white' }}>
            <div className="col-md-3"  >
    
       <LoadingOverlay active={this.state.loading} spinner    text='Loading your content...'
              >
             
            <Form onSubmit={this.handleSubmit}>
              {showLogo && (
                <div className="text-center pb-4">
                  <img
                    src={logo200Image}
                    className="rounded"
                    style={{ width: 60, height: 60, cursor: 'pointer' }}
                    alt="logo"
                    onClick={onLogoClick}
                  />
                </div>
              )}
                <FormGroup>
                  <Label for="">Enter One Time Password</Label>
                    <Input
                      bsSize="sm"
                      type="text"
                      name="otp"
                      id="otp"
                      placeholder="Enter One Time Password"
                      onChange={this.formNewInputChange}
                    />
                </FormGroup>
              
           
              <hr />
                <Button
                size="sm"
                className="border-0"
                color="primary"
                block
                onClick={this.handleSubmit}>
                Verifiy OTP
              </Button>
              <Button   size="sm" className="border-0" color="link" onClick={()=>this.onSubmit()}> Sign In </Button>
          
              {children}
            </Form>
             
      </LoadingOverlay>
    
        </div>
     
      </div>
    );
  }
  componentDidMount() {
    alert(this.props.location.state.otp.OTP)
    this.state.formNewInput.mobile=this.props.location.state.formNewInput.mobile
    this.state.formNewInput.username=this.props.location.state.formNewInput.username
    this.state.formNewInput.otp_sms=this.props.location.state.otp.OTP
    this.state.formNewInput.user_ID=this.props.location.state.otp.ID
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading:false},()=>{console.log("okkkkk")})
    if (nextProps.TYPE==='USER_REGISTRATION'){
      if (nextProps.USER_REGISTRATION.STATUS==="SUCCESS"){
        alert("User Registered Successfully: Username for login is "+nextProps.USER_REGISTRATION.USERNAME)
          nextProps.history.push({ pathname: '/' })
        
       // this.props.addToCart('',this.props.PRODUCT_LIST)
      }else{
        alert("Sorry,User Registered Failed: "+nextProps.USER_REGISTRATION.MSG)
      }
    }
    else if (nextProps.TYPE==='ADD_TO_CART'){
      nextProps.history.push({ pathname: '/' })
    }
    this.setState({loading:false},()=>{console.log("okkkkk")})
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

UserOTPRequest.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

UserOTPRequest.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};


function mapStateToProps({ mainReducers}) {
  console.log("main reducers")
  console.log(mainReducers)
   return {
     TYPE:mainReducers.TYPE,
     USER_REGISTRATION: mainReducers.USER_REGISTRATION,
     PRODUCT_LIST:mainReducers.PRODUCT_LIST,
     ADD_TO_CART:mainReducers.ADD_TO_CAR
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserOTPRequest) ;

