import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
import LoadingOverlay from 'react-loading-overlay';
import { MdVisibility, MdVisibilityOff} from 'react-icons/md';
class AuthForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formNewTask:{
        username:'',
        password:'',
      },
      loading:false,
      flag:0,
    }
  } 
 
  get isLogin() { return this.props.authState === STATE_LOGIN;  }

  get isSignup() {    return this.props.authState === STATE_SIGNUP;  }

  changeAuthState = authState => event => {this.props.onChangeAuthState(authState);  };

  handleSubmit = event => {
    this.props.loginCheck("loginCheck",this.state.formNewTask);
    this.setState({loading:true},()=>{console.log("okkkkk")})
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  formNewTaskInputChange=(event)=> {
    const key=event.target.name;
    const value=event.target.value;
    const formTask=this.state.formNewTask
    formTask[key]=value;
    this.setState({formTask})
  }

  onIconClick=()=>{
    if (this.state.flag==0){
      this.setState({flag:1},()=>{})
    }else{
      this.setState({flag:0},()=>{})
    }
  }

  onKeyPress=(e)=> {
    if (e.key === 'Enter') {
     this.handleSubmit()
    }
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;
    let inputType=this.state.flag==0?"password":"text"
    let loginButton;
    if (this.state.flag==0) {
      loginButton = <MdVisibility className={'nav-item-icon'}  style={{padding: 0}}/>;
    } else {
      loginButton = <MdVisibilityOff className={'nav-item-icon'}  style={{padding: 0}} />;
    }
    return (
      <LoadingOverlay active={this.state.loading} spinner text='Loading your content...' >
      <Form onSubmit={this.handleSubmit}  autoComplete="new-password"  autocomplete="new-password">
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
            />
          </div>
        )}
          <FormGroup>
            <Label for="username">User Name</Label>
              <Input
                bsSize="sm"
                type="text"
                name="username"
                id="username"
                placeholder="Enter User Name"
                onChange={this.formNewTaskInputChange}
                autoComplete="new-password"
                autocomplete="new-password"
              />
          </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <InputGroup size="sm">
                <Input
                  bsSize="sm"
                  type={inputType}
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  onChange={this.formNewTaskInputChange}
                  onKeyDown={this.onKeyPress}
                  autoComplete="new-password"
                  autocomplete="new-password"
              />
              <InputGroupAddon addonType="append" size="sm">
                <InputGroupText size="sm">
                  <i   onClick={()=>this.onIconClick()}>
                  {loginButton}
                    
                  </i>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
       
       
        <hr />
          <FormGroup check>
            <Label check>
              <Input type="checkbox" /> save my password
            </Label>
          </FormGroup>
          <br/>
        <Button
          size="lg"
          className="border-0"
          color="primary"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>

        {children}
      </Form>
      </LoadingOverlay>
    );
  }
  componentDidMount() {
    console.log("did mount auth form")
    console.log(this.props)
    if (this.props.type=="WEB"){
      //alert("web")
      this.props.logoutCheck("logout",'');
      this.props.userAdminLogin("LOGIN",false);
    }
    else{
      //alert("app")
      this.state.formNewTask.username=this.props.username
      this.state.formNewTask.password=this.props.password
      this.props.loginCheck("loginCheck",this.state.formNewTask);
    }
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loading:false},()=>{console.log("okkkkk")})
  }
  //////////////end here
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'text',
    placeholder: 'Enter Your Username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

function mapStateToProps({ mainReducers}) {
   return {
    TYPE:mainReducers.TYPE,
    LOGIN_CHECK: mainReducers.LOGIN_CHECK,
    USER_ADMIN_LOGIN:mainReducers.USER_ADMIN_LOGIN,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthForm) ;

