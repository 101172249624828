import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import withBadge from 'hocs/withBadge';
import React from 'react';
import SourceLink from 'components/SourceLink';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Label,
  Card,
  CardBody,
  Row,
  Col,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import bn from 'utils/bemnames';
// Redux Import FIle Start
import { connect } from "react-redux";
import { ActionCreators } from "actions";
import { bindActionCreators } from "redux";
import * as ActionTypes from "actions/ActionTypes";
// Redux Import File Ends
import { NavLink as NavLink2 } from 'react-router-dom';
import { ReactNavbar } from "react-responsive-animate-navbar";
const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small></small>,
})(MdNotificationsActive);

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationsData: [],
      profileData: [],
    }
  }

  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    isOpen: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  onSignout = event => {
    this.props.logoutCheck("logout", '');
  }


  render() {
    const { isNotificationConfirmed } = this.state;
    //alert(JSON.stringify((this.props.LOGIN_CHECK)))
    let level = this.props.LOGIN_CHECK.level
    if (level == 1) {
      return (
        <div>
          <Navbar light expand="lg" className={bem.b('bg-white')} >
            <Nav navbar className="mr-2">
              <Button outline color="primary" onClick={this.handleSidebarControlButton}>
                <MdClearAll size={20} />
              </Button>
            </Nav>

            <Navbar collapseOnSelect light expand="lg" className={bem.b('bg-white')}>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/dashboard/"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Dashboard'}</span>
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Task'}</span>

                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-new-task"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'New Task'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/change-task"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Change Task'}</span>
                        </NavLink>

                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Users'}</span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-company"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Company'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-division"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Unit'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-department"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Department'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-sub-department"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Sub Department'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-designation"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Designation'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/employee-registration"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Employee'}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem >
                        <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-view-employee"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'View Employee'}</span>
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Report'}</span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink id={"All Task"} className="text-uppercase" tag={NavLink2} to={"/report"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{"All Task"}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink id={"total_pending"} className="text-uppercase" tag={NavLink2} to={"#"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Total Pending Task"}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink id={"total_completed"} className="text-uppercase" tag={NavLink2} to={"#"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Total Completed Task"}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink id={"recntly_completed"} className="text-uppercase" tag={NavLink2} to={"#"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Recently Completed Task"}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink id={"pending"} className="text-uppercase" tag={NavLink2} to={"#"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                          <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Recently Pending Task"}</span>
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                    <NavLink id={"priority"} className="text-uppercase" tag={NavLink2} to={"/report-department-wise"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Department Wise Report"}</span>
                    </NavLink>
                  </DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <NavItem>
                    <NavLink id={"search"} className="text-uppercase" tag={NavLink2} to={"/admin-search-page"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Search'}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/user-support-ticket-new"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Support'}</span>
                    </NavLink>
                  </NavItem>

                  <NavItem className="text-uppercase" >
                    <NavLink id={"logout"} className="text-uppercase" activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} onClick={() => this.onSignout()} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'SIGN OUT'}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Navbar>
        </div>
      );

    }
    else {
      /// User if Condition
      return (
        <Navbar light expand="lg" className={bem.b('bg-white')}>
          <Nav navbar className="mr-2">
            <Button outline color="primary" onClick={this.handleSidebarControlButton}>
              <MdClearAll size={20} />
            </Button>
          </Nav>

          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/dashboard/"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Dashboard'}</span>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'TASK'}</span>

                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-new-task"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'New Task'}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem >
                    <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/change-task"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Change Task'}</span>
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UserMenu props={this.props.USER_ADMIN_LOGIN} />
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'REPORT'}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink id={"created_01"} className="text-uppercase" tag={NavLink2} to={"/report"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Total Pending Task"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"created_02"} className="text-uppercase" tag={NavLink2} to={"/report-create-date"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Date Wise Entry"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"pending_old_report"} className="text-uppercase" tag={NavLink2} to={"/report-below-date"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Date Extension Pending"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"assigned"} className="text-uppercase" tag={NavLink2} to={"/report-assigned-by"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Person Wise Pending"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"completed"} className="text-uppercase" tag={NavLink2} to={"/report-completed"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Total Completed"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"priority"} className="text-uppercase" tag={NavLink2} to={"/report-priority-wise"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Priority Wise Report"}</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink id={"priority"} className="text-uppercase" tag={NavLink2} to={"/report-department-wise"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                      <span className="" style={{ color: "#000000", fontSize: 14 }}>{"Department Wise Report"}</span>
                    </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink id={"search"} className="text-uppercase" tag={NavLink2} to={"/user-search-page"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Search'}</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id={"adminlogin"} className="text-uppercase" tag={NavLink2} to={"/user-admin-login"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Admin Login'}</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/user-support-ticket-new"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Support'}</span>
                </NavLink>
              </NavItem>

              <NavItem className="text-uppercase" >
                <NavLink id={"logout"} className="text-uppercase" activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} onClick={() => this.onSignout()} >
                  <span className="" style={{ color: "#000000", fontSize: 14 }}>{'SIGN OUT'}</span>
                </NavLink>
              </NavItem>


            </Nav>

          </Collapse>
        </Navbar>
      );
    }
  }

  componentDidMount() {
    console.log("header did mount")
    let data = { 'userID': this.props.LOGIN_CHECK.user_ID }
    this.props.viewProfile("viewProfile", data);
    //  console.log("didmount header")
  }

  componentWillReceiveProps(nextProps) {
    console.log("header will received props")
    if (nextProps.TYPE == "VIEW_PROFILE") {
      //this.setState({notificationsData:nextProps.VIEW_NOTIFICATION.VIEW_NOTIFICATION})
      this.setState((state) => {
        return {
          notificationsData: nextProps.VIEW_PROFILE.VIEW_NOTIFICATION,
          profileData: nextProps.VIEW_PROFILE.VIEW_PROFILE,
        }
      }, () => { })
    }
    else if (nextProps.TYPE == "SIDEBAR_MENU") {
      this.setState((state) => {
        return {
          mainMenu: nextProps.SIDEBAR_MENU.menu,
          dashboard: nextProps.SIDEBAR_MENU.dashboard
        };
      }, () => { });
    }
  }
  /////Class Ends Here
}

const UserMenu = ({ props }) => {
  //alert(JSON.stringify(props)+" user menu")
  //{"login":true,"msg":"Login"} user menu
  if (props.login == true) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Users'}</span>

        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-company"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Company'}</span>
            </NavLink>
          </DropdownItem>
          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-division"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Unit'}</span>
            </NavLink>
          </DropdownItem>
          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-department"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Department'}</span>
            </NavLink>
          </DropdownItem>
          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-sub-department"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Sub Department'}</span>
            </NavLink>
          </DropdownItem>

          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-add-designation"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Designation'}</span>
            </NavLink>
          </DropdownItem>
          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/employee-registration"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'Add Employee'}</span>
            </NavLink>
          </DropdownItem>
          <DropdownItem >
            <NavLink id={"newtask"} className="text-uppercase" tag={NavLink2} to={"/admin-view-employee"} activeClassName="active" exact={false} style={{ color: "#fff", fontSize: 14 }} >
              <span className="" style={{ color: "#000000", fontSize: 14 }}>{'View Employee'}</span>
            </NavLink>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  } else {
    return (
      <></>
    )
  }

}


function mapStateToProps({ mainReducers }) {
  return {
    TYPE: mainReducers.TYPE,
    VIEW_PROFILE: mainReducers.VIEW_PROFILE,
    LOGIN_CHECK: mainReducers.LOGIN_CHECK,
    SIDEBAR_MENU: mainReducers.SIDEBAR_MENU,
    USER_ADMIN_LOGIN: mainReducers.USER_ADMIN_LOGIN,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

