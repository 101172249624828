import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdOpenInNew,
  MdMenu,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Row,
  Col
} from 'reactstrap';
import bn from 'utils/bemnames';
 // Redux Import FIle Start
 import { connect } from "react-redux";
 import { ActionCreators } from "actions";
 import { bindActionCreators } from "redux";
 import * as ActionTypes from "actions/ActionTypes";
 // Redux Import File Ends
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const mainMenuOld = [
  { to: '/dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/admin-new-task', name: 'Add New Task', exact: false, Icon: MdWidgets },
  { to: '/admin-view-task', name: 'View Assign Task', exact: false, Icon: MdWidgets },
  { to: '/admin-add-company', name: 'Add Company', exact: false, Icon: MdWidgets },
  { to: '/admin-add-division', name: 'Add Unit', exact: false, Icon: MdWidgets },
  { to: '/admin-add-department', name: 'Add Department', exact: false, Icon: MdWidgets },
  { to: '/admin-add-designation', name: 'Add Designation', exact: false, Icon: MdWidgets },
  { to: '/employee-registration', name: 'Add Employee', exact: false, Icon: MdWidgets },
  { to: '/admin-view-employee', name: 'View Employee', exact: false, Icon: MdWidgets },
  { to: '/test-page', name: 'Test Page', exact: false, Icon: MdWidgets },


];


const navItems = [
  { to: '/dashboard', name: 'DASHBOARD', exact: false, Icon: MdDashboard },
];

const navItemLogout = [
  { to: '/', name: 'LOGOUT', exact: true, Icon: MdDashboard },
];

const bem = bn.create('sidebar');
const components = {
    MdDashboard: MdDashboard,
    video: MdWidgets
};
class Sidebar extends React.Component {

   constructor (props) {
    super(props)
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
      isOpenAdminCompnay:true,
      mainMenu:{},
      dashboard:{}
    }
  } 
  handelOpenCloseMenu=(index)=>{
    const Menu = this.state.mainMenu
    Menu[index].isOpen=!Menu[index].isOpen
    this.setState(this.state.mainMenu)
    // alert(Menu[index].isOpen)

  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    const dashboard=new Array()//JSON.stringify(this.state.dashboard)==="{}"?navItems:this.state.dashboard
       let month=new Date().getMonth()-1
      let year=new Date().getFullYear()
      if (month==0){
        year=Number(year)-1
        month=12

      }
       // alert(month+"--"+year)
    return (
      /* <aside className={bem.b()} data-image={sidebarBgImage}>*/ 
      <aside className={bem.b()} >
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
        
    {/************************************************************************** */}
  <Row style={{ padding :-10} }>
    <Col md={6} sm={6} xs={6}>
      <DayPicker  numberOfMonths={3} month={new Date()} inputProps={{ style: {width:100 } }}/>
    </Col>
  </Row>
<Nav vertical>
    {/*dashboard.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact} 
                    style={{color:"#fff", fontSize:14 }}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="" style={{color:"#fff", fontSize:14 }}>{name}</span>
                  </BSNavLink>
                </NavItem>
              ))*/}
   {/******************************************************************* */}
    {/*JSON.stringify(this.state.mainMenu)!="{}"?this.state.mainMenu.map(({ name, Icon , isOpen, menuItems}, index) => (
    <div>
      <NavItem
        className={bem.e('nav-item')}
        onClick={()=>this.handelOpenCloseMenu(index)}
        >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdMenu className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">{name}</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenComponents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
      </NavItem>
            <Collapse isOpen={isOpen}>
              {menuItems.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={true}
                    >
                      <span className="" style={{color:"#fff", marginLeft: '2rem', fontSize:13 }}> {name}</span>
                    </BSNavLink>
                  </NavItem>
            ))}
              </Collapse>
      </div>
        )):
      <div>

      </div>
      */} 
         {/*navItemLogout.map(({ to, name, exact, Icon }, index) => (
          <NavItem key={index} className={bem.e('nav-item')}>
            <BSNavLink
              id={`navItem-${name}-${index}`}
              //className="text-uppercase"
              tag={NavLink}
              to={to}
              activeClassName="active"
              exact={exact} 
              style={{color:"#fff", fontSize:14 }}
            >
              <Icon className={bem.e('nav-item-icon')} />
              <span className="" style={{color:"#fff", fontSize:14 }}>{name}</span>
            </BSNavLink>
          </NavItem>
        )) */}


         </Nav>
       
        </div>
      </aside>
    );
  }

  componentDidMount() {
  
    if (this.props.LOGIN_CHECK.login==true && this.props.LOGIN_CHECK.user_ID!=0){
    
        let data={
        'userID':this.props.LOGIN_CHECK.user_ID,
      }
      
      this.props.viewSidebarMenu("userSideMenu",data);
     
    }else{
      //  this.props.history.push({pathname: '/' })
    }

  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps)
    if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID==0){
       // nextProps.history.push({      pathname: '/'       })
    }else if (nextProps.LOGIN_CHECK.login==false && nextProps.LOGIN_CHECK.user_ID!=0){
        nextProps.history.push({
        pathname: '/'
        })
    }else{
       if (nextProps.TYPE=="SIDEBAR_MENU"){
          this.setState((state) => {
          return {
          mainMenu:nextProps.SIDEBAR_MENU.menu ,
          dashboard: nextProps.SIDEBAR_MENU.dashboard
          };
          },()=>{});
        }
        /*  if (nextProps.TYPE=="SIDEBAR_MENU"){
            this.setState((state) => {
            return {
            mainMenu:nextProps.SIDEBAR_MENU 
            };
            },()=>{this.setState((state)=>{
              return{
                mainMenu:{
                  ...mainMenu,
                }
              }
            })

           });
          }
  */
  
    }
  }
  //Class Ends Here
}


function mapStateToProps({ mainReducers}) {
 
   return {
     TYPE:mainReducers.TYPE,
     SIDEBAR_MENU:mainReducers.SIDEBAR_MENU,
     LOGIN_CHECK:mainReducers.LOGIN_CHECK,
     history:mainReducers.history,
   }
 }

 function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar) ;

